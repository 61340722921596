import {Request} from '../utilities/Request';

export function pay_run_user_list(this_obj, crm_user_id) {
  return Request.get(
    this_obj,
    `/api/crm_users/${crm_user_id}/pay_runs/pay_run_user_list`
  );
}

export function calculate_employees_pay(this_obj, pay_cycle_dates) {
  return Request.post(
    this_obj,
    '/api/pay_cycles/calculate_employees_pay',
    null,
    {
      pay_cycle_dates: pay_cycle_dates
    }
  );
}

export function change_public_holiday_hours(this_obj, public_holiday_id, crm_user_id, hours, rate) {
  return Request.post(
    this_obj,
    `/api/crm_users/${crm_user_id}/public_holiday_hours/change_public_holiday_hours`,
    null,
    {
      public_holiday_id: public_holiday_id,
      hours: hours,
      rate: rate
    },
  );
}

export function change_ordinary_hours(this_obj, crm_user_id,start, ends, hours, rate) {
  return Request.post(
    this_obj,
    `/api/crm_users/${crm_user_id}/pay_run_ordinary_hours/change_ordinary_hours`,
    null,
    {
      start: start,
      ends: ends,
      hours: hours,
      rate: rate
    },
  );
}

export function delete_ordinary_hours(this_obj, crm_user_id,start, ends) {
  return Request.post(
    this_obj,
    `/api/crm_users/${crm_user_id}/pay_run_ordinary_hours/delete_ordinary_hours`,
    null,
    {
      start: start,
      ends: ends
    },
  );
}

export function change_travel_food_allowance(this_obj, crm_user_id,start, ends, quantity, rate) {
  return Request.post(
    this_obj,
    `/api/crm_users/${crm_user_id}/pay_run_travel_food_allowances/change_travel_food_allowance`,
    null,
    {
      start: start,
      ends: ends,
      quantity: quantity,
      rate: rate
    },
  );
}

export function delete_travel_food_allowance(this_obj, crm_user_id,start, ends) {
  return Request.post(
    this_obj,
    `/api/crm_users/${crm_user_id}/pay_run_travel_food_allowances/delete_travel_food_allowance`,
    null,
    {
      start: start,
      ends: ends
    },
  );
}

export function change_payslip_message(this_obj, crm_user_id, payslip_email, payslip_message) {
  return Request.post(
    this_obj,
    `/api/crm_users/${crm_user_id}/pay_runs/pay_run_payslip_message`,
    null,
    {
      payslip_email: payslip_email,
      payslip_message: payslip_message
    },
  );
}

export function manual_change_days(this_obj, crm_user_id, pay_cycle_dates, changed_days) {
  console.log( pay_cycle_dates);
  return Request.post(
    this_obj,
    '/api/pay_cycles/manual_change_days',
    null,
    {
      pay_cycle_dates: pay_cycle_dates,
      crm_user_id: crm_user_id,
      changed_days: changed_days
    }
  );
}

export function pay_run_draft_pay(this_obj, crm_user_id, pay_period_start, pay_period_end) {
  return Request.post(
    this_obj,
    `/api/crm_users/${crm_user_id}/pay_runs/pay_run_draft_pay`,
    null,
    {
      pay_period_start: pay_period_start,
      pay_period_end: pay_period_end
    }
  );
}

export function pay_run_earnings(this_obj, crm_user_id, pay_period_start, pay_period_end) {
  return Request.post(
    this_obj,
    `/api/crm_users/${crm_user_id}/pay_runs/pay_run_earnings`,
    null,
    {
      pay_period_start: pay_period_start,
      pay_period_end: pay_period_end
    }
  );
}

export function pay_run_income_tax(this_obj, crm_user_id, pay_period_start, pay_period_end, gross_payment_total) {
  return Request.post(
    this_obj,
    `/api/crm_users/${crm_user_id}/pay_runs/pay_run_income_tax`,
    null,
    {
      pay_period_start: pay_period_start,
      pay_period_end: pay_period_end,
      gross_payment_total: gross_payment_total
    }
  );
}

export function pay_run_student_loan(this_obj, crm_user_id, pay_period_start, pay_period_end, gross_payment_total) {
  return Request.post(
    this_obj,
    `/api/crm_users/${crm_user_id}/pay_runs/pay_run_student_loan`,
    null,
    {
      pay_period_start: pay_period_start,
      pay_period_end: pay_period_end,
      gross_payment_total: gross_payment_total
    }
  );
}

export function pay_run_child_support(this_obj, crm_user_id, pay_period_start, pay_period_end, gross_payment_total) {
  return Request.post(
    this_obj,
    `/api/crm_users/${crm_user_id}/pay_runs/pay_run_child_support`,
    null,
    {
      pay_period_start: pay_period_start,
      pay_period_end: pay_period_end,
      gross_payment_total: gross_payment_total
    }
  );
}

export function pay_employees(this_obj, pay_cycle_dates, employee_list, employee_data) {
  return Request.post(
    this_obj,
    '/api/pay_runs/pay_employees',
    null,
    {
      pay_cycle_dates: pay_cycle_dates,
      employee_list: employee_list,
      employee_data: employee_data
    }
  );
}



