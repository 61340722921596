<template>
  <div id="draft-pay-period">
    <el-collapse accordion>
      <el-collapse-item
        v-for="pay_cycle in pay_cycle_dates"
        :key="pay_cycle.id"
        :name="pay_cycle.id"
        class="custom-collapse-item"
        @change="set_default_input();"
      >
        <template slot="title">
          <table>
            <tr>
              <td><span class="dimgrey">Employees: </span><span>{{ employee_list.filter(user => user.pay_cycle_id == pay_cycle.id).length }}</span></td>
              <td><span class="dimgrey">Pay Date: </span><span>{{ next_pay_day(pay_cycle.id) }}</span></td>
              <td><span class="dimgrey">Period: </span><span>{{ pay_cycle.start_calculated }} - {{ pay_cycle.end_calculated }}</span></td>
              <td class="right width60">
                <span><b>{{ pay_cycle.frequency }}</b></span>
              </td>
            </tr>
            <tr>
              <td />
              <td />
              <td><span class="greens">{{ public_holidays(pay_cycle.id) }}</span></td>
              <td>
                <span>Total To Pay: <money :amount="total_take_home_pay(pay_cycle.id)" /></span>
                <el-button
                  type="danger"
                  size="mini"
                  class="right margin-top"
                  @click="open_pay_run_dialog(pay_cycle.id)"
                >
                  Run Pay
                </el-button>
                <el-dialog
                  width="30%"
                  :visible.sync="pay_run_dialog"
                >
                  <div class="center">
                    <h2>Confirmation to Run Pay</h2>
                    <div>
                      Total of: <money :amount="total_take_home_pay(current_id)" /> to pay for
                      <b>{{ pay_cycle.frequency }}</b> Pay Cycle<br>
                      <b>PAY DATE: {{ next_pay_day(current_id) }}</b>
                    </div>
                    <br>
                    <div class="dialog-border">
                      THIS PAY WILL AUTOMATICALLY BE SUBMITTED <br>TO INLAND REVENUE
                    </div>
                    <br>
                    <div class="dialog-border">
                      PAYSLIPS WILL BE SENT
                    </div>
                    <el-button
                      type="primary"
                      class="left margin-top"
                      size="mini"
                      @click="pay_run_dialog = false"
                    >
                      Cancel
                    </el-button>
                    <el-button
                      :ref="pay_cycle.id"
                      :disabled="disable_send.includes(pay_cycle.id)"
                      type="danger"
                      size="mini"
                      class="right margin-top"
                      @click="pay_employees(pay_cycle.id)"
                    >
                      Yes, Run Pay
                    </el-button>
                    <br>
                  </div>
                </el-dialog>
              </td>
            </tr>
          </table>
        </template>
        <el-table
          ref="multipleTable"
          :data="employee_list.filter(user => user.pay_cycle_id == pay_cycle.id)"
          style="width: 100%"
          stripe
          show-summary
          :summary-method="get_summaries"
          :row-key="row_key"
          :expand-row-keys="expandRowKeys"
          @expand-change="set_default_input, handleExpandChange()"
        >
          <el-table-column
            v-slot="scope"
            type="expand"
          >
            <div class="margin-left earning-details">
              <table>
                <th />
                <th>Quantity</th>
                <th>Rate</th>
                <th>Amount</th>
                <tr>
                  <td>
                    <span><b>Days paid</b></span>
                    <el-link
                      class="margin-left color-link"
                      @click="show_time_sheet_dialog = true"
                    >
                      + Add hours to Timesheet
                    </el-link>
                    <el-dialog
                      :visible.sync="show_time_sheet_dialog"
                      :close-on-click-modal="true"
                      append-to-body
                      width="70%"
                    >
                      <time-sheet
                        :crm_user_id="scope.row.id"
                        @hook:mounted="set_default_input"
                      />
                    </el-dialog>
                  </td>
                  <td>
                    <span>{{ scope.row.working_hours_in_days }} days  | {{ scope.row.working_hours }} hours</span>
                  </td>
                  <td><money :amount="scope.row.pay_rate" /></td>
                  <td><money :amount="scope.row.working_hours_pay_rate" /></td>
                </tr>
                <tr class="border-bottom-none">
                  <td><span><b>Earnings</b></span></td>
                </tr>
                <tr
                  v-for="(day, index) in scope.row.public_holiday"
                  :key="index"
                >
                  <td>
                    <span class="margin-left-10">Public holiday</span>
                    <span>
                      {{ day.date_formatted }} {{ day.name }}
                    </span>
                  </td>
                  <td>
                    <span>Hours</span>
                    <el-input
                      v-if="scope.row.public_holiday[index].public_holiday_hours.length > 0"
                      v-model="scope.row.public_holiday[index].public_holiday_hours[0].hours"
                      class="width80"
                      size="small"
                    />
                  </td>
                  <td>
                    <span>$</span>
                    <el-input
                      v-if="scope.row.public_holiday[index].public_holiday_hours.length > 0"
                      v-model="scope.row.public_holiday[index].public_holiday_hours[0].rate"
                      class="width80"
                      size="small"
                    />
                    <el-button
                      size="mini"
                      type="success"
                      icon="el-icon-check"
                      circle
                      @click="update_public_holiday_hours(day.id, scope.row.id, index, scope.$index)"
                    />
                  </td>
                  <td>
                    <span>
                      <money :amount="scope.row.public_holiday[index].public_holiday_hours[0].total" />
                    </span>
                  </td>
                </tr>
                <tr v-if="scope.row.leave_details.public_holiday > 0 && scope.row.public_holiday.length > 1">
                  <td />
                  <td />
                  <td />
                  <td>
                    <span>
                      <money :amount="scope.row.leave_details.public_holiday" />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td><span class="margin-left-10">Ordinary hours</span></td>
                  <td>
                    <span>Hours</span>
                    <el-input
                      v-model="scope.row.working_hours"
                      class="width80"
                      size="small"
                    />
                  </td>
                  <td>
                    <span>$</span>
                    <el-input
                      v-model="scope.row.pay_rate"
                      class="width80"
                      size="small"
                    />
                    <el-button
                      size="mini"
                      type="success"
                      icon="el-icon-check"
                      circle
                      @click="update_ordinary_hours(scope.$index, scope.row.id)"
                    />
                    <el-button
                      size="mini"
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      @click="destroy_ordinary_hours(scope.$index, scope.row.id)"
                    />
                  </td>
                  <td>
                    <span>
                      <money :amount="scope.row.working_hours_pay_rate" />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="margin-bottom"><b>Expenses</b></span>
                    <br>
                    <span class="margin-left-10">Travel Food Allowance</span>
                  </td>
                  <td>
                    <br>
                    <el-input
                      v-model="scope.row.travel_food_allowance.quantity"
                      class="width80"
                      size="small"
                    />
                  </td>
                  <td>
                    <br>
                    <span>$</span>
                    <el-input
                      v-model="scope.row.travel_food_allowance.rate"
                      class="width80"
                      size="small"
                    />
                    <el-button
                      size="mini"
                      type="success"
                      icon="el-icon-check"
                      circle
                      @click="update_travel_food_allowance(scope.$index, scope.row.id)"
                    />
                    <el-button
                      size="mini"
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      @click="destroy_travel_food_allowance(scope.$index, scope.row.id)"
                    />
                  </td>
                  <td>
                    <br>
                    <span>
                      <money :amount="scope.row.travel_food_allowance.total" />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span><b>Holidays and leave</b></span>
                    <el-link
                      class="margin-left color-link"
                      @click="show_leave_request_dialog = true"
                    >
                      + Add Holidays or leave
                    </el-link>
                    <el-dialog
                      :visible.sync="show_leave_request_dialog"
                      :close-on-click-modal="true"
                      append-to-body
                      width="500px"
                    >
                      <new-leave-request-plain
                        :crm_user_id="scope.row.id"
                        @reload_leave_requests="get_pay_cycle_dates(),get_leave_requests('reload_leave_requests',pay_cycle.start_calculated, pay_cycle.end_calculated, scope.row.id )"
                      />
                    </el-dialog>
                  </td>
                </tr>
                <tr>
                  <td colspan="5">
                    <leave-requests-user
                      :key="leave_request_user_reload"
                      :start="pay_cycle.start"
                      :end="pay_cycle.end"
                      :crm_user_id="scope.row.id"
                      @reload_pay_run="get_pay_cycle_dates"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span><b>Superannuation</b></span>
                  </td>
                </tr>
                <tr>
                  <td><span class="margin-left-10">Employee contribution {{ scope.row.kiwi_saver_contributions }}%</span></td>
                  <td />
                  <td />
                  <td />
                  <td><money :amount="scope.row.superannuation_employee" /></td>
                </tr>
                <tr>
                  <td><span class="margin-left-10">Employer contribution {{ scope.row.employer_contributions }}%</span></td>
                  <td />
                  <td />
                  <td />
                  <td><money :amount="scope.row.superannuation" /></td>
                </tr>
                <tr>
                  <td><span class="margin-left-10">Employer superannuation contribution tax (ESCT) {{ scope.row.superannuation_esct_rate_employer }}%</span></td>
                  <td />
                  <td />
                  <td />
                  <td><money :amount="scope.row.superannuation_tax" /></td>
                </tr>
                <tr>
                  <td />
                  <td />
                  <td />
                  <td><b>Total Earning:</b> <money :amount="scope.row.gross_payment_total" /></td>
                  <td><b>Taxes:</b> <money :amount="scope.row.income_tax" /></td>
                </tr>
                <tr>
                  <td>
                    <b>Payslips</b>
                  </td>
                </tr>
                <tr>
                  <td>
                    Payslip email:
                    <el-input
                      v-model="scope.row.payslip_email"
                      class="width240"
                      size="small"
                    />
                    <el-button
                      size="mini"
                      type="success"
                      icon="el-icon-check"
                      circle
                      @click="update_payslip_message(scope.$index, scope.row.id)"
                    />
                    <br><br>
                    Payslip message:
                    <el-input
                      v-model="scope.row.payslip_message"
                      type="textarea"
                      :autosize="{ minRows: 1}"
                      size="small"
                    />
                  </td>
                </tr>
              </table>
            </div>
          </el-table-column>
          <el-table-column
            label="Name"
            property="name"
            width="200px"
          >
            <template #default="scope">
              <el-link
                class="color-link"
                @click="edit_employee(scope.row.id)"
              >
                {{ scope.row.name }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column
            label="Earnings"
            prop="gross_payment_total"
          >
            <template #default="scope">
              <money :amount="scope.row.gross_payment_total" />
            </template>
          </el-table-column>
          <el-table-column
            label="Leave"
            prop="leave"
          >
            <template #default="scope">
              <el-link
                class="color-link"
                @click="edit_leave(pay_cycle.start_calculated, pay_cycle.end_calculated, scope.row.id)"
              >
                <money :amount="scope.row.leave_details.leave_period_sum" />
              </el-link>
            </template>
          </el-table-column>
          <el-table-column
            label="Reimbursements"
            prop="total_reimbursements"
          >
            <template #default="scope">
              <money :amount="scope.row.total_reimbursements" />
            </template>
          </el-table-column>
          <el-table-column
            label="Deductions"
            prop="total_deduction"
          >
            <template #default="scope">
              <money :amount="scope.row.total_deduction" />
            </template>
          </el-table-column>
          <el-table-column
            label="Superannuation"
            prop="superannuation"
          >
            <template #default="scope">
              <money :amount="scope.row.superannuation" />
            </template>
          </el-table-column>
          <el-table-column
            label="Take Home Pay"
            prop="take_home_pay"
          >
            <template #default="scope">
              <money :amount="scope.row.take_home_pay" />
            </template>
          </el-table-column>
          <el-table-column
            align="right"
          >
            <template #default="scope">
              <el-dropdown @command="payslip">
                <span class="el-dropdown-link">
                  Actions<i class="el-icon-arrow-down el-icon--right" />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="{id: scope.row.id, action: 'download_payslip'}">
                    Download Payslip
                  </el-dropdown-item>
                  <el-dropdown-item :command="{id: scope.row.id, action: 'email_payslip'}">
                    Email Payslip To Employee
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
          <el-table-column
            align="right"
          >
            <template #default="scope">
              <el-popconfirm
                title="Are you sure you want to remove the employee from this pay run?"
                @confirm="remove_employee(scope.row.id)"
              >
                <el-button
                  slot="reference"
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  size="mini"
                />
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-collapse-item>
    </el-collapse>
    <leave-requests-period
      v-if="showDialog == true"
      :start="start_calculated"
      :end="end_calculated"
      :crm_user_id="crm_user_id"
      :show_dialog.sync="showDialog"
      @reload_pay_run="get_pay_cycle_dates"
    />
  </div>
</template>

<script>

import {set_default_input} from '../../utilities/DefaultInput';
import {pay_cycle_dates} from '../../api/pay_cycles';
import {
  pay_employees,
  calculate_employees_pay,
  change_public_holiday_hours,
  change_ordinary_hours,
  delete_ordinary_hours,
  change_travel_food_allowance,
  delete_travel_food_allowance,
  change_payslip_message
} from '../../api/pay_runs';
import {leave_requests} from '../../api/leave_requests';
import Money from '../shared/Money.vue';
import LeaveRequestsPeriod from './LeaveRequestsPeriod.vue';
import LeaveRequestsUser from './LeaveRequestsUser.vue';
import TimeSheet from '../user/TimeSheet.vue';
import NewLeaveRequestPlain from '../user/payroll/NewLeaveRequestPlain.vue';

export default {
  name: 'DraftPayPeriods',
  components: {TimeSheet, Money, LeaveRequestsUser, LeaveRequestsPeriod, NewLeaveRequestPlain},
  data() {
    return {
      pay_cycle_dates: [{start_calculated: null, end_calculated: null}],
      employee_list: [],
      employee_data: [],
      disable_send: [],
      showDialog: false,
      start_calculated: null,
      end_calculated: null,
      crm_user_id: null,
      show_time_sheet_dialog: false,
      show_leave_request_dialog: false,
      expandRowKeys: [],
      leave_request_user_reload: 0,
      pay_run_dialog: false,
      current_id: null
    };
  },
  created() {
    this.get_pay_cycle_dates();
    this.set_default_input();
  },
  methods: {
    open_pay_run_dialog(id){
      this.current_id = id;
      this.pay_run_dialog = true;
    },
    total_take_home_pay(id){
      const employee = this.employee_list.filter(user => user.pay_cycle_id === id);
      if (!employee) {
        return '';
      }
      const take_home_pay = employee.reduce((sum, employee) => sum + employee.take_home_pay, 0);
      return  take_home_pay;
    },
    public_holidays(id){
      const employee = this.employee_list.find(user => user.pay_cycle_id === id);
      if (!employee || !employee.public_holiday || employee.public_holiday.length === 0) {
        return '';
      }
      const names = employee.public_holiday.map(holiday => holiday.name).join(', ');
      const count = employee.public_holiday.length;

      return `${count} Public Holiday${count > 1 ? 's' : ''}: ${names}`;
    },
    next_pay_day(id){
      const employee = this.employee_list.find(user => user.pay_cycle_id === id);
      if (!employee || !employee.next_pay_day) {
        return '';
      }

      return employee.next_pay_day;
    },
    handleExpandChange(row) {
      this.expandRowKeys.push(row.id);
    },
    row_key(row) {
      return row.id;
    },
    update_payslip_message(index, crm_user_id){
      let payslip_email = this.employee_list[index].payslip_email;
      let payslip_message = this.employee_list[index].payslip_message;
      change_payslip_message(this, crm_user_id, payslip_email, payslip_message).then((response)=> {
        this.$message({ type: 'success', message: response.data.message });
      });
    },
    update_ordinary_hours(index, crm_user_id){
      let hours = this.employee_list[index].working_hours;
      let rate =  this.employee_list[index].pay_rate;
      let start = this.employee_list[index].pay_period_start;
      let ends = this.employee_list[index].pay_period_end;
      change_ordinary_hours(this, crm_user_id, start, ends, hours, rate).then((response)=> {
        this.$message({ type: 'success', message: response.data.message });
        this.calculate_employees_pay(this.pay_cycle_dates);
      });
    },
    destroy_ordinary_hours(index, crm_user_id){
      let start = this.employee_list[index].pay_period_start;
      let ends = this.employee_list[index].pay_period_end;
      delete_ordinary_hours(this, crm_user_id, start, ends).then((response)=> {
        this.$message({ type: 'error', message: response.data.message });
        this.calculate_employees_pay(this.pay_cycle_dates);
      });
    },
    update_travel_food_allowance(index, crm_user_id){
      let quantity = this.employee_list[index].travel_food_allowance.quantity;
      let rate =  this.employee_list[index].travel_food_allowance.rate;
      let start = this.employee_list[index].pay_period_start;
      let ends = this.employee_list[index].pay_period_end;
      change_travel_food_allowance(this, crm_user_id, start, ends, quantity, rate).then((response)=> {
        this.$message({ type: 'success', message: response.data.message });
        this.calculate_employees_pay(this.pay_cycle_dates);
      });
    },
    destroy_travel_food_allowance(index, crm_user_id){
      let start = this.employee_list[index].pay_period_start;
      let ends = this.employee_list[index].pay_period_end;
      delete_travel_food_allowance(this, crm_user_id, start, ends).then((response)=> {
        this.$message({ type: 'error', message: response.data.message });
        this.calculate_employees_pay(this.pay_cycle_dates);
      });
    },
    update_public_holiday_hours(public_holiday_id, crm_user_id, index, list_index){
      let hours = this.employee_list[list_index].public_holiday[index].public_holiday_hours[0].hours || 0;
      let rate =  this.employee_list[list_index].public_holiday[index].public_holiday_hours[0].rate || 0;
      change_public_holiday_hours(this, public_holiday_id, crm_user_id, hours, rate).then((response)=> {
        this.$message({ type: 'success', message: response.data.message });
        this.calculate_employees_pay(this.pay_cycle_dates);
      });
    },
    get_leave_requests(event, start, end, crm_user_id){
      this.leave_request_user_reload++;
      leave_requests(this, start, end, crm_user_id ).then((response)=> {
        this.leave_requests = response.data;
        if(event == 'reload_leave_requests'){
          this.show_leave_request_dialog = false;
          this.$emit('reload_pay_run');
        }
      });
    },
    edit_leave(start_calculated, end_calculated, crm_user_id){
      this.start_calculated = start_calculated;
      this.end_calculated = end_calculated;
      this.crm_user_id = crm_user_id;
      this.showDialog = true;
    },
    payslip(command){
      if(command.action == 'download_payslip'){
        this.download_payslip(command.id);
      }
      if(command.action == 'email_payslip'){
        this.email_payslip(command.id);
      }
    },
    pay_employees(pay_cycle_id){
      this.disable_send.push(pay_cycle_id);
      let employee_list_current_pay_cycle = this.employee_list.filter(user => user.pay_cycle_id == pay_cycle_id);
      let pay_cycle = this.pay_cycle_dates.filter(pay_cycle => pay_cycle.id == pay_cycle_id);
      pay_employees(this, pay_cycle, employee_list_current_pay_cycle, this.employee_data).then(() => {
        this.$message({ type: 'success', message: 'Processing Pay Run...' });
        this.$emit('reload_draft_pay_transactions');
      });
    },
    email_payslip(id){
      this.employee_data = this.employee_list.filter(user => user.id == id);
      this.$http.post(`/api/crm_users/${id}/pay_runs/pay_run_email_payslip`,
        { employee_data:  this.employee_data }
      ).then( () => {
        this.$message({ type: 'success', message: 'Email to employee sent.' });
      });
    },
    download_payslip(id){
      this.employee_data = this.employee_list.filter(user => user.id == id);
      this.$http.post(`/api/crm_users/${id}/pay_runs/pay_run_download_payslip`,
        { employee_data:  this.employee_data },
        { responseType: 'blob' }
      ).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.headers.get('content-disposition').split('UTF-8\'\'')[1].trim());
        document.body.appendChild(link);
        link.click();
      });
    },
    remove_employee(id){
      this.employee_list = this.employee_list.filter(user => user.id != id);
    },
    edit_employee(id) {
      window.open(`/crm_users/${id}/edit#/employment_details`, '_blank');
    },
    calculate_employees_pay(pay_cycle_dates) {
      calculate_employees_pay(this, pay_cycle_dates).then((response) => {
        this.employee_list = response.data;
      });
    },
    get_pay_cycle_dates() {
      pay_cycle_dates(this).then((response)=> {
        this.pay_cycle_dates = response.data;
        // sort: oldest pay cycle start first
        this.pay_cycle_dates.sort((a, b) => new Date(a.start) - new Date(b.start));
        this.calculate_employees_pay(this.pay_cycle_dates);
      });
    },
    handleSelectionChange(val) {
      this.selected_employees = val;
    },
    get_summaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = 'Total Cost';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] =  '$' + values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return (prev + curr);
            } else {
              return  prev;
            }
          }, 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        } else {
          sums[index] = ' ';
        }
      });

      return sums;
    },
    set_default_input
  }
};

</script>

<style scoped>
.dialog-border{ border: 1px solid green; padding: 20px; text-align: center;}
.greens{color: green;}
.dimgrey{ color: dimgrey;}
.custom-collapse-item:nth-child(1) >>> .el-collapse-item__header {
  background-color: #E5F6DF;
}

.custom-collapse-item:nth-child(2) >>> .el-collapse-item__header {
  background-color: darkgrey;
}

.custom-collapse-item:nth-child(3) >>> .el-collapse-item__header {
  background-color: lightgrey;
}
th {font-size: 12px;font-weight: normal;color: grey;}
td, th {padding: 0px;}
.earning-details tr {
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
}
.earning-details td {
  padding: 10px 0px;
}
.earning-details td:first-child {
  padding-left: 10px;
}
.margin-left{ margin-left: 20px;}
.width60 {width: 88px;}
.block{display: block;}
.margin-bottom{ margin-bottom: 8px;display: inline-block;}
.margin-left-10{ margin-left: 10px;}
.margin-top{margin-top: 20px;}
.width40{ width: 40px;}
.width80{ width: 80px;}
.width240{ width: 240px;}
.color-link{color: #409EFF !important;}
.center{text-align: center;}
</style>
<style>
#draft-pay-period .el-card { margin-bottom: 20px;}
.el-table__expand-icon>.el-icon {
  margin-top: -10px !important; }
#draft-pay-period .el-table__expand-icon {
  position: unset; }
#draft-pay-period .el-collapse-item__arrow {margin-top: -50px;}
#draft-pay-period .el-collapse-item__header{ line-height: 22px;}
.border-bottom-none{border-bottom: 1px solid white !important;}
</style>
